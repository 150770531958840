<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-input v-model="listQuery.order_no" placeholder="请输入订单号" class="filter-item" style="width: 250px;" clearable />

      <el-input v-model="listQuery.sub_mch_id" placeholder="请输入特约商户号" class="filter-item" style="width: 200px;" clearable />

      <el-input v-model="listQuery.keyword" placeholder="请输入姓名、手机号码" class="filter-item" style="width: 200px;" clearable />

      <el-select v-model="listQuery.order_status" placeholder="订单状态" class="filter-item" style="width: 140px;" clearable>
        <el-option label="待支付" :value="0" />
        <el-option label="待接单" :value="1" />
        <el-option label="已接单" :value="2" />
        <el-option label="待补差价" :value="3" />
        <el-option label="配送中" :value="4" />
        <el-option label="已完成" :value="5" />
        <el-option label="已取消" :value="6" />
      </el-select>

      <el-select v-model="listQuery.pay_status" placeholder="支付状态" class="filter-item" style="width: 140px;" clearable>
        <el-option label="待支付" :value="0" />
        <el-option label="已支付" :value="1" />
        <el-option label="已退款" :value="2" />
      </el-select>

      <el-select v-model="listQuery.split_order_status" placeholder="分账订单状态" class="filter-item" style="width: 140px;" clearable>
        <el-option label="无需分账" :value="0" />
        <el-option label="待分账" :value="1" />
        <el-option label="分账中" :value="2" />
        <el-option label="分账完成" :value="3" />
        <el-option label="分账失败" :value="4" />
      </el-select>

      <el-select v-model="listQuery.is_finish_split" placeholder="是否完结分账" class="filter-item" style="width: 140px;" clearable>
        <el-option label="未完结分账" :value="0" />
        <el-option label="已完结分账" :value="1" />
      </el-select>

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="代取订单列表.xls" worksheet="代取订单列表">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading" style="margin-bottom: 20px;">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.payMoney || 0 }}</div>
            <div class="subtitle">累计消费金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.payCount || 0 }}</div>
            <div class="subtitle">累计消费次数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.refundMoney || 0 }}</div>
            <div class="subtitle">累计退款金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.refundCount || 0 }}</div>
            <div class="subtitle">累计退款次数</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>

      <el-table-column label="特约商户号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sub_mch_id }}
        </template>
      </el-table-column>

      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pickup_order_receiver.name }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pickup_order_receiver.phone }}
        </template>
      </el-table-column>

      <el-table-column label="地址" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pickup_order_receiver.address }}
        </template>
      </el-table-column>

      <el-table-column label="复制短信内容" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.copy_sms_content }}
        </template>
      </el-table-column>

      <el-table-column label="快递公司" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.courier_company.name }}
        </template>
      </el-table-column>

      <el-table-column label="代取费用名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pickup_fee_name }}
        </template>
      </el-table-column>

      <el-table-column label="代取费用价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pickup_fee_price }}
        </template>
      </el-table-column>

      <el-table-column label="备注" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.remarks }}
        </template>
      </el-table-column>

      <el-table-column label="订单状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.order_status === 0">待支付</el-tag>
          <el-tag type="warning" v-else-if="scope.row.order_status === 1">待接单</el-tag>
          <el-tag type="warning" v-else-if="scope.row.order_status === 2">已接单</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_status === 3">待补差价</el-tag>
          <el-tag type="warning" v-else-if="scope.row.order_status === 4">配送中</el-tag>
          <el-tag type="success" v-else-if="scope.row.order_status === 5">已完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.order_status === 6">已取消</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="支付状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.pay_status === 0">待支付</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_status === 1">已支付</el-tag>
          <el-tag type="warning" v-else-if="scope.row.pay_status === 2">已退款</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="支付时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_time }}
        </template>
      </el-table-column>

      <el-table-column label="接单时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.receiver_time }}
        </template>
      </el-table-column>

      <el-table-column label="打印状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.print_status === 0">未打印</el-tag>
          <el-tag type="warning" v-else-if="scope.row.print_status === 1">打印中</el-tag>
          <el-tag type="success" v-else-if="scope.row.print_status === 2">打印成功</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="取消时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.cancel_time }}
        </template>
      </el-table-column>

      <el-table-column label="完成时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.finish_time }}
        </template>
      </el-table-column>

      <el-table-column label="配送员" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.sendman" style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <span>{{ scope.row.sendman.name }}</span>
            <span>{{ scope.row.sendman.phone }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="差价单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.difference_price_no }}
        </template>
      </el-table-column>

      <el-table-column label="差价分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.difference_price_split_no }}
        </template>
      </el-table-column>

      <el-table-column label="差价金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.difference_price_amount }}
        </template>
      </el-table-column>

      <el-table-column label="差价支付时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.difference_price_pay_time }}
        </template>
      </el-table-column>

      <el-table-column label="订单总价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_price }}
        </template>
      </el-table-column>

      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.split_order_no }}
        </template>
      </el-table-column>

      <el-table-column label="分账订单状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.split_order_status === 0">无需分账</el-tag>
          <el-tag type="info" v-else-if="scope.row.split_order_status === 1">待分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.split_order_status === 2">分账中</el-tag>
          <el-tag type="success" v-else-if="scope.row.split_order_status === 3">分账完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.split_order_status === 4">分账失败</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="分账错误原因" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.split_order_status === 4 ? scope.row.split_error_msg : "" }}
        </template>
      </el-table-column>

      <el-table-column label="订单分账金额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.finance_ledger_money }}</div>
        </template>
      </el-table-column>

      <el-table-column label="下单时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handlePickupOrderSplitLog(scope.row)">分账收入记录</el-button>

          <el-button type="warning" size="mini" @click="handlePickupOrderFinishSplit(scope.row)" :disabled="!(scope.row.split_order_status === 4 && scope.row.is_finish_split === 0)">完结分账</el-button>

          <el-button type="danger" size="mini" @click="handlePickupOrderCancel(scope.row)" :disabled="!(scope.row.order_status !== 5 && scope.row.order_status !== 6)">取消订单</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";
var moment = require("moment");

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        order_no: "",
        sub_mch_id: "",
        keyword: "",
        order_status: "",
        pay_status: "",
        split_order_status: "",
        is_finish_split: "",
        start_date: "",
        end_date: "",
      },
      dateArr: null,
      payMoney: null,
      payCount: null,
      refundMoney: null,
      refundCount: null,
      json_fields: {
        订单号: {
          field: "order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        特约商户号: "sub_mch_id",
        姓名: "pickup_order_receiver.name",
        手机号码: {
          field: "pickup_order_receiver.phone",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        地址: "pickup_order_receiver.address",
        复制短信内容: "copy_sms_content",
        快递公司: "courier_company.name",
        代取费用名称: "pickup_fee_name",
        代取费用价格: "pickup_fee_price",
        备注: "remarks",
        订单状态: {
          field: "order_status",
          callback: (value) => {
            if (value === 0) return "待支付";
            else if (value === 1) return "待接单";
            else if (value === 2) return "已接单";
            else if (value === 3) return "待补差价";
            else if (value === 4) return "配送中";
            else if (value === 5) return "已完成";
            else if (value === 6) return "已取消";
            else return "";
          }
        },
        支付状态: {
          field: "pay_status",
          callback: (value) => {
            if (value === 0) return "待支付";
            else if (value === 1) return "已支付";
            else if (value === 2) return "已退款";
            else return "";
          }
        },
        支付时间: {
          field: "pay_time",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        接单时间: {
          field: "receiver_time",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        打印状态: {
          field: "print_status",
          callback: (value) => {
            if (value === 0) return "未打印";
            else if (value === 1) return "打印中";
            else if (value === 2) return "打印成功";
            else return "";
          }
        },
        取消时间: {
          field: "cancel_time",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        完成时间: {
          field: "finish_time",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        配送员姓名: "sendman.name",
        配送员手机号码: {
          field: "sendman.phone",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        差价单号: {
          field: "difference_price_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        差价分账单号: {
          field: "difference_price_split_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        差价金额: "difference_price_amount",
        差价支付时间: {
          field: "difference_price_pay_time",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        订单总价: "total_price",
        分账单号: {
          field: "split_order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        分账订单状态: {
          field: "split_order_status",
          callback: (value) => {
            if (value === 0) return "无需分账";
            else if (value === 1) return "待分账";
            else if (value === 2) return "分账中";
            else if (value === 3) return "分账完成";
            else if (value === 4) return "分账失败";
            else return "";
          }
        },
        分账错误原因: "split_error_msg",
        订单分账金额: "finance_ledger_money",
        下单时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
      },
      exportLoading: false,
    };
  },
  created() {
    this.listQuery.start_date = moment().format("YYYY-MM-DD");
    this.listQuery.end_date = moment().format("YYYY-MM-DD");
    this.dateArr = [this.listQuery.start_date, this.listQuery.end_date];
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_date = newVal[0];
        this.listQuery.end_date = newVal[1];
      } else {
        this.listQuery.start_date = "";
        this.listQuery.end_date = "";
      }
    }
  },
  methods: {
    getList() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/pickupOrder/list",
        method: "get",
        params: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.payMoney = response.data.payMoney;
        this.payCount = response.data.payCount;
        this.refundMoney = response.data.refundMoney;
        this.refundCount = response.data.refundCount;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handlePickupOrderSplitLog(item) {
      this.$router.push(`/pickup/orderSplitLog?order_no=${item.order_no}`);
    },
    handlePickupOrderFinishSplit(item){
      request({
        url: "/api/backend/pickupOrder/finishSplit",
        method: "post",
        data: {
          order_no: item.order_no
        }
      }).then(() => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        this.getList();
      });
    },
    handlePickupOrderCancel(item){
      this.$confirm("确定要取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/pickupOrder/cancel",
          method: "post",
          data: {
            order_no: item.order_no
          }
        }).then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.getList();
        });
      });
    },
    handleExport() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/pickupOrder/list",
        method: "get",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.list.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.list.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
